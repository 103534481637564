<template>
  <div class="menus">
    <el-menu :default-active="active" :default-openeds="subs">
      <el-menu-item index="/index" @click="() => {to('/index')}">
        <i class="iconfont icon-shujudaping"></i>
        <span slot="title">数据中心</span>
      </el-menu-item>
      <el-menu-item index="/device" @click="() => {to('/device')}">
        <i class="iconfont icon-shebeiguanli"></i>
        <span slot="title">设备管理</span>
      </el-menu-item>
      <!-- <el-menu-item index="/contact/workshoprun" @click="() => {to('/contact/workshoprun')}">
        <i class="iconfont icon-chejian"></i>
        <span slot="title">车间管理</span>
      </el-menu-item> -->
      <el-menu-item index="/contact/record" @click="() => {to('/contact/record')}">
        <i class="iconfont icon-shouye"></i>
        <span slot="title">意向记录</span>
      </el-menu-item>
      <!-- <el-submenu index="/my/contact">
        <template slot="title">
          <i class="iconfont icon-wangzhantubiaoji2_huaban1fuben9"></i>
          <span>保养</span>
        </template>
        <el-menu-item index="/contact/servicelevel" @click="() => {to('/contact/servicelevel')}">
          <i class="iconfont icon-wangzhantubiaoji2_huaban1fuben9"></i>
          <span slot="title">保养标准</span>
        </el-menu-item>
        <el-menu-item index="/contact/serviceplan" @click="() => {to('/contact/serviceplan')}">
          <i class="iconfont icon-jihua"></i>
          <span slot="title">保养计划</span>
        </el-menu-item>
      </el-submenu> -->
      <el-submenu index="/usermanage">
        <template slot="title">
          <i class="el-icon-user"></i>
          <span>我的</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/contact/order" @click="() => {to('/contact/order')}">
            <i class="el-icon-s-order"></i>
            <span slot="title">我的订单</span>
          </el-menu-item>
          <el-menu-item index="/contact/address" @click="() => {to('/contact/address')}">
            <i class="el-icon-location-information"></i>
            <span slot="title">我的地址</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-menu-item index="/contact/info" @click="() => {to('/contact/info')}">
        <i class="el-icon-bell"></i>
        <span slot="title">系统消息</span>
      </el-menu-item>
      <el-menu-item index="/contact/connect" @click="() => {to('/contact/connect')}">
        <i class="el-icon-phone-outline"></i>
        <span slot="title">联系我们</span>
      </el-menu-item>
      <el-menu-item index="/feedback" @click="() => {to('/feedback')}">
        <i class="iconfont icon-yijianfankui"></i>
        <span slot="title">意见反馈</span>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
        active: '',
        subs: ["/usermanage", "/my/contact"],
    };
  },
  methods: {
    to(path){
        this.$router.push(path)
    }
  },
  beforeMount() {
    this.active = this.$route.path
  },
};
</script>

<style lang="scss" scoped>
    .menus{
      width: 200px;
      min-width: 200px;
      background-color: #FFFFFF;
      z-index: 10;
    }
    ::v-deep .el-menu{
      /* background-color: transparent; */
      border-right: none;

      .el-menu-item:focus, .el-menu-item:hover {
        outline: 0;
        background-color: rgba(255, 136, 136, 0.2);
      }
      .is-active{
        color: #ed1b23;
      }
      .el-submenu{
        .el-submenu__title:hover{
          background-color: rgba(255, 136, 136, 0.2) !important;
        }
      }
      .el-menu-item,
      .el-submenu{
        i{
          font-size: 16px;
          width: 16px;
          margin-right: 4px;
        }
      }
      >.el-menu-item:nth-last-child(3){
        // margin-top: 60px;
      }
    }
</style>
