<template>
    <div class="layout" v-show="show">
        <layhead></layhead>
        <div class="main">
            <laymenu></laymenu>
            <div class="view">
                <router-view></router-view>
            </div>
        </div>
        <foot></foot>
    </div>
</template>

<script>
import layhead from "@/components/home/Nav.vue";
import laymenu from "@/components/menu.vue";
import foot from "@/components/home/Footer"

export default {
    components: {
        layhead,
        laymenu,
        foot,
    },
    data() {
        return {
            show: false,
        }
    },
    beforeMount() {
        if(!sessionStorage.getItem("page")){
            sessionStorage.setItem("page", "layout")
            window.location.reload()
        }
        else{
            this.show = true
        }
    },
    beforeDestroy() {
        sessionStorage.removeItem("page")
        document.body.style.display = "none"
    },
    destroyed() {
        window.location.reload()
    },
}
</script>

<style lang="scss" scoped>
    .layout{
        display: flex;
        flex-direction: column;
        background: #f0f2f5;
        .main{
            // height: 100vh;
            min-height: 100vh;
            display: flex;
            .view{
                // height: 100%;
                min-width: 600px;
                box-sizing: border-box;
                padding-top: 22px;
                padding-left: 12px;
                padding-right: 12px;
                // overflow-y: scroll;
                flex: 1;
                &::-webkit-scrollbar-track,
                &::-webkit-scrollbar{
                    display: none;
                }
                >div{
                    min-height: 100%;
                    background-color: #FFFFFF;
                    box-sizing: border-box;
                    padding: 22px;
                }
            }
        }
    }
</style>
<style>
    *,html,body{
        margin: 0;
        padding: 0;
    }
</style>